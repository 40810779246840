<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                   
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                            <span slot="action" slot-scope="text, record">
                                <a @click="showtaskDetail(record.ID)">详情</a>
                                <a-divider type="vertical" />
                                <a @click="showtaskDetailedInfo(record.ID)">明细</a>
                                <a-divider type="vertical" />
                                <a @click="showtaskDetailRankingInfo(record.ID)">排行</a>
                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "充值金额",
                        dataIndex: "RechargeMoney",
                        width: 200,
                        ellipsis: true
                    },
                    {
                        title: "账户余额",
                        dataIndex: "AccountMoney",
                        width: 200,
                        ellipsis: true
                    },
                    {
                        title: "已发金额",
                        dataIndex: "GantRankingMoney",
                        width: 200,
                        ellipsis: true
                    },
                    {
                        title: "创建时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "创建人",
                        dataIndex: "UserName",
                        width: 200,
                        ellipsis: true
                    },
                    {
                        title: "状态",
                        width: 200,
                        customRender: (text, item) => {
                            var rt;
                            switch (item.PublishStatus) {
                                case 0:
                                    rt = "未生效";
                                    break;
                                case 1:
                                    rt = "已生效";
                                    break;
                                case 2:
                                    rt = "已结束";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: '操作',
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PromotionType: "-1",
                States: 0,
            };
        },
        props: {//组件属性
        },
        methods: {//方法

            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/PromoteStimulateTeamRedEnvelopesConfigList",
                    data: {
                        PageIndex: self.pagination.current,
                        States: self.PromotionType,
                    },
                    OnSuccess: function (data) {
                           data.data.Data.forEach(element => {
                               element.RechargeMoney = parseFloat(element.RechargeMoney).toFixed(2);
                               element.AccountMoney = parseFloat(element.AccountMoney).toFixed(2);
                               element.UpgradeRewardMoney = parseFloat(element.UpgradeRewardMoney).toFixed(2);
                               element.RankingRewardMoney = parseFloat(element.RankingRewardMoney).toFixed(2);

                        });
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            //详情
            showtaskDetail: function (e) {
                //console.log(e);
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateTeamRedEnvelopesConfig_Detail",
                    query: { ID: e }
                });

            },

            //明细
            showtaskDetailedInfo: function (e) {
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateTeamRedEnvelopesConfig_DetailInfo",
                    query: { ID: e }
                });
            },

            //排行
            showtaskDetailRankingInfo: function (e) {
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateTeamRedEnvelopesConfig_RankingInfo",
                    query: { ID: e }
                });

            }


        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },

        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };


</script>